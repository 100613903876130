import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { FullContactInfo, State } from 'types/general';
import { SaveContactInfoResponse, SaveInfo, UTMData } from 'types/onboarding';
import { HomeDetails as RebateEligibilityHomeDetails } from 'types/rebate-eligibility';
import { API_Endpoint } from '../utils/api';
import { APICall, MAHouseholdMembers, NYHouseholdMembers } from 'types';
import { HeaConfirmationOption, InsulationOption } from 'types/onboarding';
import { BaseWithNotSureOption } from 'types/general';

export interface Rebates {
  heat_pump: string;
  heat_pump_water_heater: string;
  home_energy_assessment: string;
  insulation: string;
}

export interface TaxIncentives {
  heat_pump: string;
  electric_panel_upgrade: string;
  insulation: string;
}

export const onboardingApi = createApi({
  reducerPath: 'onboardingApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_Endpoint }),
  tagTypes: ['getHomeDetails'],
  endpoints: (builder) => ({
    leadFormSubmit: builder.mutation<
      { existing_lead_id: String; redirect_url: string },
      {
        form_data: {
          fields: {
            full_name: string;
            address: string;
            email: string;
            phone_number: string;
            marketing_consent: string;
            hea?: string;
            home_occupancy?: string;
            insulation?: string;
            fuel_assistance?: string;
          };
          rebate_flow: boolean;
        };
      }
    >({
      query: (formDataInput) => {
        const formData = new FormData();
        (
          Object.keys(formDataInput.form_data.fields) as Array<
            keyof typeof formDataInput.form_data.fields
          >
        ).forEach((key) => {
          const value = formDataInput.form_data.fields[key];
          if (value) {
            formData.append(`fields[${key}]`, value);
          }
        });

        formData.append(
          'rebate_flow',
          String(formDataInput.form_data.rebate_flow)
        );

        return {
          url: 'ecomm/lead_form_submit',
          method: 'POST',
          body: formData,
        };
      },
    }),
    saveContactInfo: builder.mutation<
      SaveContactInfoResponse,
      {
        contactInfo: FullContactInfo;
        utmData?: UTMData;
        lead_source?: String;
        referrer?: String;
        rebate_flow?: boolean;
      }
    >({
      query: ({
        contactInfo,
        utmData = {
          campaign: '',
          medium: '',
          source: '',
          content: '',
          term: '',
          gclid: '',
          _gl: '',
        },
        lead_source = '',
        referrer = '',
        rebate_flow = false,
      }) => ({
        url: 'ecomm/save_contact_info',
        method: 'POST',
        body: {
          ...contactInfo,
          utm_campaign: utmData.campaign,
          utm_medium: utmData.medium,
          utm_source: utmData.source,
          utm_content: utmData.content,
          utm_term: utmData.term,
          gclid: utmData.gclid,
          _gl: utmData._gl,
          lead_source: lead_source,
          referrer: referrer,
          rebate_flow: rebate_flow,
        },
      }),
    }),
    saveInfo: builder.mutation<{ status: string }, SaveInfo>({
      query: (saveInfo) => ({
        url: 'ecomm/save_info',
        method: 'POST',
        body: saveInfo,
      }),
    }),
    leadOwner: builder.query<
      {
        calendly_link: string;
        email: string;
        id: string;
        name: string;
        phone: string;
      },
      string
    >({
      query: (phone) => {
        const queryParams = new URLSearchParams({ phone });
        return `ecomm/lead_owner?${queryParams}`;
      },
    }),
    getHomeDetails: builder.query<
      {
        data: {
          home_data: RebateEligibilityHomeDetails;
          postal_address: {
            city: string;
            state: string;
            street_address: string;
            zip_code: string;
          };
        };
      },
      {
        address: string;
      }
    >({
      query: ({ address }) => ({
        url: `ecomm/lookup`,
        method: 'POST',
        body: { address },
      }),
      providesTags: ['getHomeDetails'],
    }),
    isServiceable: builder.query<
      {
        status: 'serviceable' | 'not_serviceable';
      },
      {
        address: string;
      }
    >({
      query: ({ address }) => ({
        url: `ecomm/is_serviceable`,
        method: 'POST',
        body: { address },
      }),
    }),
    rebates: builder.query<
      {
        rebates: Rebates;
        tax_incentives: TaxIncentives;
        ny_rebate_program?: string;
      },
      {
        customer_email: string;
        hea: HeaConfirmationOption;
        homeDataId: string;
        homeOccupancy: BaseWithNotSureOption;
        householdIncome: string;
        householdMembers: MAHouseholdMembers | NYHouseholdMembers;
        insulationMeasure: InsulationOption;
        state: State;
      }
    >({
      query: ({
        customer_email,
        hea,
        homeDataId,
        homeOccupancy,
        householdMembers,
        householdIncome,
        insulationMeasure,
        state,
      }) => ({
        url: `ecomm/rebates`,
        method: 'POST',
        body: {
          customer_email,
          hea: hea === 'had-assessment',
          insulation_measure: insulationMeasure,
          home_data_id: homeDataId,
          household_members:
            householdMembers === '' ? undefined : householdMembers,
          household_income:
            householdIncome === '' ? undefined : householdIncome,
          summer_home: homeOccupancy === 'no',
          state,
        },
      }),
    }),
    futureLead: builder.mutation<
      APICall,
      {
        name?: string;
        address: string;
        email: string;
        phone?: string;
        fuel_assistance?: string;
        household_members?: string;
        household_income?: string;
        home_occupancy?: string;
        hea?: string;
        insulation_measure?: string;
      }
    >({
      query: ({
        name,
        address,
        email,
        phone,
        fuel_assistance,
        household_members,
        household_income,
        home_occupancy,
        hea,
        insulation_measure,
      }) => ({
        url: `ecomm/future_lead`,
        method: 'POST',
        body: {
          address,
          email,
          fuel_assistance: fuel_assistance ? fuel_assistance === 'yes' : null,
          hea: hea ? hea === 'yes' : null,
          household_income: household_income ? household_income : null,
          household_members: household_members ? household_members : null,
          insulation_measure: insulation_measure ? insulation_measure : null,
          name,
          phone,
          summer_home: home_occupancy ? home_occupancy === 'no' : null,
          page: window.location.pathname,
        },
      }),
    }),
  }),
});

export const {
  useLeadFormSubmitMutation,
  useSaveContactInfoMutation,
  useSaveInfoMutation,
  useLeadOwnerQuery,
  useGetHomeDetailsQuery,
  useIsServiceableQuery,
  useRebatesQuery,
  useFutureLeadMutation,
} = onboardingApi;
